@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts/gotham';
@import './fonts/social';
@import './modules/toastify/main';

@layer base {
	@import 'modules/base';
	@import 'modules/forms';
	@import 'modules/badges';
	@import 'modules/animations';
}
