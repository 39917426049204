.#{$rt-namespace}__toast {
	position: relative;
	min-height: var(--toastify-toast-min-height);
	box-sizing: border-box;
	margin-bottom: 1rem;
	padding: 8px;
	border-radius: 4px;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
	display: flex;
	justify-content: space-between;
	max-height: var(--toastify-toast-max-height);
	overflow: hidden;
	font-size: 0.9rem;
	font-family: var(--toastify-font-family);
	letter-spacing: 0;
	cursor: default;
	direction: ltr;
	/* webkit only issue #791 */
	z-index: 0;
	&--rtl {
		direction: rtl;
	}
	&--close-on-click {
		cursor: pointer;
	}
	&-body {
		margin: auto 0;
		flex: 1 1 auto;
		padding: 6px;
		display: flex;
		align-items: center;
		& > div:last-child {
			word-break: break-word;
			flex: 1;
		}
	}
	&-icon {
		margin-right: 0.75em;
		width: 1.4em;
		flex: 0 1 1.4em;
	}
}

.#{$rt-namespace}--animate {
	animation-fill-mode: both;
	animation-duration: 0.4s;
}

.#{$rt-namespace}--animate-icon {
	animation-fill-mode: both;
	animation-duration: 0.3s;
}

@media #{$rt-mobile} {
	.#{$rt-namespace}__toast {
		margin-bottom: 0;
		border-radius: 0;
	}
}
