$rt-namespace: 'Toastify';
$rt-mobile: 'only screen and (max-width : 480px)' !default;

:root {
	--toastify-color-light: theme('colors.white');
	--toastify-color-dark: theme('colors.black');
	--toastify-color-info: #3498db;
	--toastify-color-success: #07bc0c;
	--toastify-color-warning: #f1c40f;
	--toastify-color-error: #e74c3c;
	--toastify-color-transparent: rgba(255, 255, 255, 0.7);

	--toastify-icon-color-info: var(--toastify-color-info);
	--toastify-icon-color-success: var(--toastify-color-success);
	--toastify-icon-color-warning: var(--toastify-color-warning);
	--toastify-icon-color-error: var(--toastify-color-error);

	--toastify-toast-width: 48rem;
	--toastify-toast-background: #fff;
	--toastify-toast-min-height: 1.2em;
	--toastify-toast-max-height: 800px;
	--toastify-font-family: inherit;
	--toastify-z-index: 9999;

	--toastify-text-color-light: theme('colors.black');
	--toastify-text-color-dark: theme('colors.white');

	//Used only for colored theme
	--toastify-text-color-info: #fff;
	--toastify-text-color-success: #fff;
	--toastify-text-color-warning: #fff;
	--toastify-text-color-error: #fff;

	--toastify-spinner-color: #616161;
	--toastify-spinner-color-empty-area: #e0e0e0;

	// Used when no type is provided
	--toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
	// Used when no type is provided
	--toastify-color-progress-dark: #bb86fc;
	--toastify-color-progress-info: var(--toastify-color-info);
	--toastify-color-progress-success: var(--toastify-color-success);
	--toastify-color-progress-warning: var(--toastify-color-warning);
	--toastify-color-progress-error: var(--toastify-color-error);
}
