.animate-focus {
	animation: focusKeyframes 0.5s ease-in-out;
	animation-iteration-count: 3;
}

@keyframes focusKeyframes {
	0%,
	100% {
		background-color: initial;
	}

	50% {
		background-color: theme('colors.primary.300'/20%);
	}
}
